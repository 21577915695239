import React from "react";
import img1 from "../../images/p3.png";
import img2 from "../../images/p4.png";
import { Link } from "gatsby";

const BecomePartner = () => {
  return (
    <div
      id="becomePartner"
      className="w-full px-6 py-24 m-auto max-w-7xl md:px-10 md:py-12"
    >
      <div className="flex flex-col items-center justify-center w-full mb-8 md:mb-16">
        <h1 className="mt-12 mb-5 text-2xl font-bold text-center uppercase lg:mt-0 md:text-4xl">
          Become A Partner
        </h1>
        <p className="max-w-2xl mb-6 text-center">
          DealerSys is the most generous master agency in terms of payouts to
          referral partners.  Let’s win big together!
        </p>

        <div className="grid grid-cols-1 gap-5 mt-5 lg:grid-cols-2">
          <div className="flex flex-col gap-4 ">
            <div className="lg:max-h-80">
              <img className="w-full h-full rounded-2xl" src={img1} alt="" />
            </div>
            <div className="p-4">
              <h3 className="mb-2 text-2xl">Auto Referral Agent</h3>
              <p>
                Do you have contacts with decision makers within automotive?  We
                want to help as many Dealers as possible, but we also want to
                help as many folks earn significant side income.  We recognize
                the value of your relationships, and ensure that you are
                generously compensated for them.  No involvement needed.  Making
                money has never been easier!
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-4 ">
            <div className="lg:max-h-80">
              <img
                className="object-cover w-full h-full rounded-2xl "
                src={img2}
                alt=""
              />
            </div>
            <div className="p-4">
              <h3 className="mb-2 text-2xl">Traditional Agent</h3>
              <p>
                As a DealerSys Agent you will be able to sell all of our
                services and benefits with increased revenue. Our agents benefit
                from customized commission structures, based on involvement and
                volume. Because of our established relationships with our
                partners, you are able to market the full portfolio of products
                to your existing customer database along with new clients and
                prospects.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <Link to="/contact-us">
          <span className="font-medium text-sm bg-black text-white md:px-28 px-12 py-3.5 rounded-lg transition-all hover:shadow-lg">
            Contact Us
          </span>
        </Link>
      </div>
    </div>
  );
};

export default BecomePartner;
