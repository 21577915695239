import React from "react";
import BecomePartner from "./BecomePartner";
import Speak from "./../../components/Speak";

const Index = ({location}) => {
  return (
    <div>
      <BecomePartner />
      <Speak  location={location}/>
    </div>
  );
};

export default Index;
