import React from "react";
import Index from "../views/BecomePartner/Index";
import Layout from "../components/Layout";
const BecomePartner = ({location}) => {
  return (
    <div>
      {" "}
 <Layout location={location}>
        <Index  location={location}/>
      </Layout>
    </div>
  );
};

export default BecomePartner;
